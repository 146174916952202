import React from "react";
import "./GalleryCard.css";

export default function GalleryCard({ bgImg, bgSize }) {
  return (
    <div
      className="gallery-card"
      style={{
        backgroundImage: `url(${bgImg})`,
        backgroundSize: bgSize && "cover",
      }}
    ></div>
  );
}
