import React from "react";
import PageHero from "../../components/PageHero";
import "./Report.css";
import doc1 from "../../documents/doc1.PDF";
import doc2 from "../../documents/doc2.PDF";
import doc3 from "../../documents/doc3.PDF";
import doc4 from "../../documents/doc4.pdf";

import img1 from "../../img/2.png";
import img2 from "../../img/3.png";
import img3 from "../../img/1.png";
import img4 from "../../img/4.png";

export default function Report() {
  const reportCard = ({ title, document, bgImg }) => (
    <a
      className="report-card"
      href={document}
      target={"_blank"}
      rel="noreferrer"
    >
      <div className="image" style={{ backgroundImage: `url(${bgImg})` }}></div>
      <div className="doc-title">{title}</div>
    </a>
  );
  return (
    <div className="report">
      <PageHero title={"reports"} />
      <div className="container">
        {reportCard({
          title: "2023 POST-IWD CELEBRATIONS REPORT",
          document: doc1,
          bgImg: img1,
        })}
        {reportCard({
          title:
            "A REPORT ON 3RD ANNIVERSARY CELEBRATION FOR HOPE FOR WIDOWS IN KAJIADO",
          document: doc2,
          bgImg: img2,
        })}
        {reportCard({
          title: "PRE-IWD 2023 CELEBRATIONS REPORT",
          document: doc3,
          bgImg: img3,
        })}
        {reportCard({
          title: "REPORT ON THE 67TH CSW",
          document: doc4,
          bgImg: img4,
        })}
      </div>
    </div>
  );
}
