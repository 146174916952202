import React, { useEffect, useState } from "react";
import Content from "../../components/Content";
import PageHero from "../../components/PageHero";
import "./LegalAid.css";
import img from "../../img/widows-law.jpeg";
import img2 from "../../img/IMG_2527.JPG";
import img3 from "../../img/widows-health.jpeg";

export default function LegalAid() {
  const [isMobileSize, setIsMobileSize] = useState(false);
  useEffect(() => {
    if (window.innerWidth < 992) {
      setIsMobileSize(true);
    } else {
      setIsMobileSize(false);
    }
  }, []);
  return (
    <div className="legal-aid">
      <PageHero title={"Legal Aid"} />
      <div className="Content">
        <div className="content-container">
          <div
            className="content-img"
            style={{ backgroundImage: `url(${img})` }}
          ></div>
          <div className="content-content">
            <p>
              As Come Together Widows and Orphans Organization, our mission is
              to shine a spotlight on the injustices inflicted upon widows,
              taking our advocacy efforts to those in positions of authority who
              possess the capacity to enact change. We are dedicated to exposing
              the systemic challenges widows face and holding accountable those
              who can influence and rectify these issues. By engaging with
              policymakers and influential figures, we aim to address and
              eradicate the root causes of widowhood-related injustices,
              ultimately working towards a more equitable and just society for
              widows and their families.
            </p>
          </div>
        </div>
      </div>
      <div className="Content">
        <div
          className="content-container"
          style={
            isMobileSize
              ? { flexDirection: "column" }
              : { flexDirection: "row-reverse" }
          }
        >
          <div
            className="content-img"
            style={{ backgroundImage: `url(${img2})` }}
          ></div>
          <div className="content-content">
            <p>
              In a powerful testament to the vital work of our organization, we
              recently encountered a distressing case involving a widow who had
              fallen victim to a horrific act of violence at the hands of her
              own brother-in-law. The widow, brutally beaten and cut with a
              panga, became the focal point of our mission to champion the
              rights of widows. With unwavering determination, we swiftly
              reported the matter to the Directorate of Criminal Investigation
              (DCI), taking a decisive stand against such heinous acts of
              injustice. Thanks to our diligent efforts and the collaborative
              commitment of the DCI, justice was served, and the matter was
              successfully resolved. This episode underscores the indispensable
              role we play in advocating for the safety, dignity, and rights of
              widows, and it strengthens our resolve to continue fighting for a
              world where such acts are met with swift and resolute justice.
            </p>
          </div>
        </div>
      </div>
      <div className="Content">
        <div className="content-container">
          <div
            className="content-img"
            style={{ backgroundImage: `url(${img3})` }}
          ></div>
          <div className="content-content">
            <p>
              Come together Widows and Orphans organization team's up with
              partners who have legal expertise FIDA Kenya, Commission of
              Administrative Justice (CAJ), Kenya Human Rights Commission(KHRC)
              and Kituo cha Sheria whereby we offer paralegal trainings to our
              widows as well as training them on as paralegals. Through this we
              are able to achieve Sustainable Development Goals SDG 5(Gender
              Equality) and SDG 10, (Reduced inequalities). This also enables
              widows to represent themselves in courts and fight for their
              rights.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
