import React from "react";
import PageHero from "../../components/PageHero";
import "./OurFunders.css";
import img from "../../img/partner8.jpeg";

export default function OurFunders() {
  return (
    <div className="our-funders">
      <PageHero title={"our funders"} />
      <div className="container">
        <div className="content">
          <div
            className="image"
            style={{ backgroundImage: `url(${img})` }}
          ></div>
          <div className="footer">
            <div className="logo">
              <img src={img} alt="" />
            </div>
            <div className="name">Global fund for widows</div>
          </div>
        </div>
      </div>
    </div>
  );
}
