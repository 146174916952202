import React from "react";
import "./Footer.css";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

export default function Footer() {
  return (
    <footer id="footer">
      <div className="footer-top">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-md-6 footer-contact">
              <h3>CTWOO</h3>
              <p>
                1st Floor, Room 110, Blessed House,
                <br />
                Thika Superhighway Next to Astrol Petrol Station
                <br />
                Kenya <br />
                <br />
                <strong>Phone:</strong> +254789577476
                <br />
                <strong>Phone:</strong> +254702577477
                <br />
                <strong>Email:</strong> info@cometogewoo.org
                <br />
              </p>
              <div className="icons">
                <a
                  href="https://www.facebook.com/cometogetherwidowsandorphans?mibextid=ZbWKwL"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <FacebookIcon />
                </a>
                <a
                  href="https://www.facebook.com/cometogetherwidowsandorphans?mibextid=ZbWKwL"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <InstagramIcon />
                </a>
                <a
                  href="https://twitter.com/Cometogether_Wi?t=nrNaPO8apLmPUSaFENY-Ew&s=08"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <TwitterIcon />
                </a>
                <a
                  href="https://www.instagram.com/dianah_kamande/"
                  target={"_blank"}
                  rel="noreferrer"
                >
                  <LinkedInIcon />
                </a>
              </div>
            </div>

            <div className="col-lg-4 col-md-6 footer-links">
              <h4>Where We At</h4>
              <iframe
                title="map"
                src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3988.9000610596336!2d36.87439907938717!3d-1.2292835323437514!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x182f1507d6673e71%3A0xd3970af731f0ccd1!2sBlessed%20House-%20OFC%20Building%20Thika%20Road!5e0!3m2!1sen!2ske!4v1692371455230!5m2!1sen!2ske"
                width="600"
                height="450"
                allowFullScreen=""
                loading="lazy"
                style={{ border: 0, width: "100%", height: "290px" }}
                referrerPolicy="no-referrer-when-downgrade"
              ></iframe>
            </div>
          </div>
        </div>
      </div>

      <div className="container footer-bottom clearfix">
        <div className="copyright">
          &copy; Copyright{" "}
          <strong>
            <span>CTWOO</span>
          </strong>
          . All Rights Reserved
        </div>
        <div className="credits">
          Designed by
          <a href="https://wansenziroz.com/" target="_blank" rel="noreferrer">
            Wansenrizo
          </a>
        </div>
      </div>
    </footer>
  );
}
