import React from "react";
import "./PageHero.css";

export default function PageHero({ title }) {
  return (
    <div className="page-hero">
      <div className="ph-container">
        <div className="ph-content">
          <p>{title}</p>
        </div>
      </div>
    </div>
  );
}
