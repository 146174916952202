import React, { useEffect, useState } from "react";
import Content from "../../components/Content";
import PageHero from "../../components/PageHero";
import "./ClimateAction.css";
import img from "../../img/widows-climate.jpeg";
import img2 from "../../img/humanitarian.jpeg";
import img3 from "../../img/agri1.jpeg";
import img4 from "../../img/widows-tree.jpeg";

export default function ClimateAction() {
  const [isMobileSize, setIsMobileSize] = useState(false);
  useEffect(() => {
    if (window.innerWidth < 992) {
      setIsMobileSize(true);
    } else {
      setIsMobileSize(false);
    }
  }, []);
  return (
    <div className="climate-action">
      <PageHero title={"Climate Action and Widowhood"} />
      <div className="container">
        <div className="description">
          Climate change and Widowhood- As Come Together Widows and Orphans
          Organization we have realised that the intersection of climate change
          and widowhood highlights a pressing concern that demands multifaceted
          attention. As an organization focused on empowering widows,
          recognizing the impact of climate change on their lives has been
          crucial for comprehensive support.
        </div>
        <div className="images">
          <div
            className="image"
            style={{ backgroundImage: `url(${img})` }}
          ></div>
          <div
            className="image"
            style={{ backgroundImage: `url(${img2})` }}
          ></div>
          <div
            className="image"
            style={{ backgroundImage: `url(${img3})` }}
          ></div>
          <div
            className="image"
            style={{ backgroundImage: `url(${img4})` }}
          ></div>
        </div>
      </div>
    </div>
  );
}
