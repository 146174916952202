import React from "react";
import PageHero from "../../components/PageHero";
import "./Education.css";

import img2 from "../../img/orphan.JPG";
import img3 from "../../img/education-support.jpeg";
import img4 from "../../img/orphans-reached.jpeg";

export default function Education() {
  return (
    <div className="education">
      <PageHero title={"Education support"} />

      <div className="container">
        <div className="images">
          <div
            className="image"
            style={{ backgroundImage: `url(${img2})` }}
          ></div>
          <div className="image text">
            We believe in the power of education to shape a brighter future. Our
            Orphans Program provides essential education support, ensuring that
            orphaned children have access to quality education, learning
            resources, and a conducive environment to thrive academically.We
            already has a total of 110 orphans and vulnerable children who we
            have been supporting their education.
          </div>
          <div
            className="image"
            style={{ backgroundImage: `url(${img3})` }}
          ></div>
          <div
            className="image"
            style={{ backgroundImage: `url(${img4})` }}
          ></div>
        </div>
      </div>
    </div>
  );
}
