import React, { useEffect, useState } from "react";
import "./Header.css";
import logo from "../img/logo512.png";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";

export default function Header({ isFullBg }) {
  const [isMobileView, setIsMobileView] = useState(false);
  const [isMobileSize, setIsMobileSize] = useState(false);
  window.addEventListener("resize", () => {
    if (window.innerWidth < 992) {
      setIsMobileSize(true);
    } else {
      setIsMobileSize(false);
    }
  });
  useEffect(() => {
    if (window.innerWidth < 992) {
      setIsMobileSize(true);
    } else {
      setIsMobileSize(false);
    }
  }, []);

  const dropDownMouseIn = (e) => {
    e.currentTarget.classList.add("hovered");
  };
  const dropDownMouseLeave = (e) => {
    e.currentTarget.classList.remove("hovered");
  };
  return (
    <div
      className="header"
      style={
        isFullBg
          ? { background: "#5902aa" }
          : { background: "linear-gradient(to top, transparent, #5902aa)" }
      }
    >
      <div className="logo">
        <img src={logo} alt="ctwoo logo" />
      </div>
      {isMobileSize && (
        <MenuIcon
          onClick={() => {
            setIsMobileView((current) => (current = !current));
          }}
          className="menu"
        />
      )}
      <nav
        className={`navbar ${!isMobileSize && "active"} ${
          isMobileView && "mobile-view"
        }`}
      >
        <div className="nav-item">
          <p className="nav-option">
            <Link
              onClick={() => {
                setIsMobileView(false);
              }}
              to={"/"}
              className="link  active"
            >
              HOME
            </Link>
          </p>
        </div>
        <div
          className="nav-item"
          onMouseEnter={(e) => dropDownMouseIn(e)}
          onMouseLeave={(e) => dropDownMouseLeave(e)}
        >
          <p className="nav-option">
            ABOUT <ArrowDropDownIcon />
          </p>
          <div className="drop-down">
            <p className="drop-down-nav-option">
              <Link
                onClick={() => {
                  setIsMobileView(false);
                }}
                to={"/who-we-are"}
                className="link "
              >
                Who we are
              </Link>
            </p>
            <p className="drop-down-nav-option">
              <Link
                onClick={() => {
                  setIsMobileView(false);
                }}
                to={"/what-we-do"}
                className="link "
              >
                What we do
              </Link>
            </p>
            <p className="drop-down-nav-option">
              <Link
                onClick={() => {
                  setIsMobileView(false);
                }}
                to={"/our-board"}
                className="link "
              >
                Our Team
              </Link>
            </p>
            {/* <p className="drop-down-nav-option">
              <Link onClick={()=> {setIsMobileView(false)}} to={"/our-staff"} className="link ">
                Our staff
              </Link>
            </p> */}
            <p className="drop-down-nav-option">
              <Link
                onClick={() => {
                  setIsMobileView(false);
                }}
                to={"/our-funders"}
                className="link "
              >
                Our funders
              </Link>
            </p>
            <p className="drop-down-nav-option">
              <Link
                onClick={() => {
                  setIsMobileView(false);
                }}
                to={"/our-partners"}
                className="link "
              >
                Our partners
              </Link>
            </p>
            <p className="drop-down-nav-option">
              <Link
                onClick={() => {
                  setIsMobileView(false);
                }}
                to={"/county-chapters"}
                className="link "
              >
                County chapters
              </Link>
            </p>
            <p className="drop-down-nav-option">
              <Link
                onClick={() => {
                  setIsMobileView(false);
                }}
                to={"/declaration-of-beliefs"}
                className="link "
              >
                Declaration of Beliefs
              </Link>
            </p>
          </div>
        </div>
        <div
          className="nav-item"
          onMouseEnter={(e) => dropDownMouseIn(e)}
          onMouseLeave={(e) => dropDownMouseLeave(e)}
        >
          <p className="nav-option">
            WIDOWS <ArrowDropDownIcon />
          </p>
          <div className="drop-down">
            <p className="drop-down-nav-option">
              <Link
                onClick={() => {
                  setIsMobileView(false);
                }}
                to={"/legal-aid"}
                className="link "
              >
                Legal Aid
              </Link>
            </p>
            <p className="drop-down-nav-option">
              <Link
                onClick={() => {
                  setIsMobileView(false);
                }}
                to={"/economic-and-empowerment"}
                className="link "
              >
                Economic Empowerment
              </Link>
            </p>
            <p className="drop-down-nav-option">
              <Link
                onClick={() => {
                  setIsMobileView(false);
                }}
                to={"/health"}
                className="link "
              >
                Health
              </Link>
            </p>
            <p className="drop-down-nav-option">
              <Link
                onClick={() => {
                  setIsMobileView(false);
                }}
                to={"/gender-based-violence"}
                className="link "
              >
                Gender Based Violence
              </Link>
            </p>
            <p className="drop-down-nav-option">
              <Link
                onClick={() => {
                  setIsMobileView(false);
                }}
                to={"/skills-training"}
                className="link "
              >
                Skills Training
              </Link>
            </p>
            <p className="drop-down-nav-option">
              <Link
                onClick={() => {
                  setIsMobileView(false);
                }}
                to={"/climate-action"}
                className="link "
              >
                Climate action
              </Link>
            </p>
          </div>
        </div>
        <div
          className="nav-item"
          onMouseEnter={(e) => dropDownMouseIn(e)}
          onMouseLeave={(e) => dropDownMouseLeave(e)}
        >
          <p className="nav-option">
            ORPHANS <ArrowDropDownIcon />
          </p>
          <div className="drop-down">
            <p className="drop-down-nav-option">
              <Link
                onClick={() => {
                  setIsMobileView(false);
                }}
                to={"/education-support"}
                className="link "
              >
                Education Support
              </Link>
            </p>
            <p className="drop-down-nav-option">
              <Link
                onClick={() => {
                  setIsMobileView(false);
                }}
                to={"/mentorship-programme"}
                className="link "
              >
                Mentorship Programme
              </Link>
            </p>
            <p className="drop-down-nav-option">
              <Link
                onClick={() => {
                  setIsMobileView(false);
                }}
                to={"/children-visits"}
                className="link "
              >
                Children Visits
              </Link>
            </p>
          </div>
        </div>
        <div
          className="nav-item"
          onMouseEnter={(e) => dropDownMouseIn(e)}
          onMouseLeave={(e) => dropDownMouseLeave(e)}
        >
          <p className="nav-option">
            CLIMATE ACTION <ArrowDropDownIcon />
          </p>
          <div className="drop-down">
            <p className="drop-down-nav-option">
              <Link
                onClick={() => {
                  setIsMobileView(false);
                }}
                to={"/tree-planting"}
                className="link "
              >
                Tree planting
              </Link>
            </p>
            <p className="drop-down-nav-option">
              <Link
                onClick={() => {
                  setIsMobileView(false);
                }}
                to={"/bee-keeping"}
                className="link "
              >
                Bee Keeping
              </Link>
            </p>
            <p className="drop-down-nav-option">
              <Link
                onClick={() => {
                  setIsMobileView(false);
                }}
                to={"/agriculture-and-food"}
                className="link "
              >
                Agriculture and food
              </Link>
            </p>
            <p className="drop-down-nav-option">
              <Link
                onClick={() => {
                  setIsMobileView(false);
                }}
                to={"/humanitarian-crisis-management"}
                className="link "
              >
                Humanitarian Crisis Management
              </Link>
            </p>
          </div>
        </div>
        <div
          className="nav-item"
          onMouseEnter={(e) => dropDownMouseIn(e)}
          onMouseLeave={(e) => dropDownMouseLeave(e)}
        >
          <p className="nav-option">
            <Link
              onClick={() => {
                setIsMobileView(false);
              }}
              to={"/declaration-of-beliefs"}
              className="link"
            >
              Creed
            </Link>
          </p>
        </div>
        <div
          className="nav-item"
          onMouseEnter={(e) => dropDownMouseIn(e)}
          onMouseLeave={(e) => dropDownMouseLeave(e)}
        >
          <p className="nav-option">
            <Link
              onClick={() => {
                setIsMobileView(false);
              }}
              to={"/gallery"}
              className="link"
            >
              GALLERY
            </Link>
          </p>
        </div>
        <div
          className="nav-item"
          onMouseEnter={(e) => dropDownMouseIn(e)}
          onMouseLeave={(e) => dropDownMouseLeave(e)}
        >
          <p className="nav-option">
            <Link
              onClick={() => {
                setIsMobileView(false);
              }}
              to={"/report"}
              className="link"
            >
              REPORT
            </Link>
          </p>
        </div>
      </nav>
      <Link
        onClick={() => {
          setIsMobileView(false);
        }}
        to={"/donate"}
        className="link"
      >
        <div className="donate-btn">
          <div className="inner-btn">DONATE</div>
        </div>
      </Link>
    </div>
  );
}
