import React, { useEffect, useState } from "react";
import PageHero from "../../components/PageHero";
import "./Humanitarian.css";
import img from "../../img/widows-human.jpeg";
import img1 from "../../img/humanitarian.jpeg";
import img2 from "../../img/humaniterian.jpeg";
import img3 from "../../img/humaniterian1.jpeg";

export default function Humanitarian() {
  const [isMobileSize, setIsMobileSize] = useState(false);
  useEffect(() => {
    if (window.innerWidth < 992) {
      setIsMobileSize(true);
    } else {
      setIsMobileSize(false);
    }
  }, []);
  return (
    <div className="humanitarian">
      <PageHero title={"HUMANITARIAN CRISIS MANAGEMENT"} />

      <div className="Content">
        <div
          className="content-container"
          style={
            isMobileSize
              ? { flexDirection: "column" }
              : { flexDirection: "row-reverse" }
          }
        >
          <div
            className="content-img"
            style={{ backgroundImage: `url(${img2})` }}
          ></div>
          <div className="content-content">
            <p>
              At Come Together Widows and Orphans Organization, our mission goes
              beyond addressing the social and economic needs of widows and
              orphans. We recognize the critical role we play in humanitarian
              crisis management, and we have extended our efforts to tackle
              pressing challenges such as drought management and food insecurity
              mostly affecting widows and their children.
            </p>
          </div>
        </div>
      </div>
      <div className="Content">
        <div className="content-container">
          <div
            className="content-img"
            style={{ backgroundImage: `url(${img})` }}
          ></div>
          <div className="content-content">
            <p>
              In collaboration with Manna Mission, we are actively engaged in an
              initiative that directly impacts the lives of widows. One
              significant step we've taken is the establishment of a borehole in
              Samburu, aimed at providing a sustainable water source to both
              widows and the wider community. This project not only enhances
              access to clean water but also reinforces the resilience of the
              entire area against the adverse effects of water scarcity.
            </p>
          </div>
        </div>
      </div>
      <div className="Content">
        <div
          className="content-container"
          style={
            isMobileSize
              ? { flexDirection: "column" }
              : { flexDirection: "row-reverse" }
          }
        >
          <div
            className="content-img"
            style={{ backgroundImage: `url(${img1})` }}
          ></div>
          <div className="content-content">
            <p>
              Understanding the multifaceted challenges faced by communities in
              harsh climates, we have taken proactive measures to address the
              loss of livestock in the region particularly in the widow’s
              households. Recognizing that animals are a crucial source of
              livelihood for many, especially widows, we have provided goats to
              widows, contributing to both their economic stability and the
              revival of local agriculture. This approach not only mitigates the
              impact of harsh climates on livelihoods but also fosters a sense
              of empowerment and independence among widows.
            </p>
          </div>
        </div>
      </div>
      <div className="Content">
        <div className="content-container">
          <div
            className="content-img"
            style={{ backgroundImage: `url(${img3})` }}
          ></div>
          <div className="content-content">
            <p>
              Our commitment to humanitarian crisis management extends beyond
              immediate relief. By engaging in sustainable projects that address
              underlying issues, we are working towards building a more
              resilient and self-reliant community. Come Together Widows and
              Orphans Organization remains dedicated to creating lasting
              solutions that uplift widows, orphans.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
