import React, { useEffect, useState } from "react";
import Content from "../../components/Content";
import PageHero from "../../components/PageHero";
import "./WhoWeAre.css";
import img from "../../img/whoweare.jpeg";
import img2 from "../../img/hero2.jpeg";
import img3 from "../../img/hero1.jpg";

export default function WhoWeAre() {
  const [isMobileSize, setIsMobileSize] = useState(false);
  useEffect(() => {
    if (window.innerWidth < 992) {
      setIsMobileSize(true);
    } else {
      setIsMobileSize(false);
    }
  }, []);
  return (
    <div className="who-we-are">
      <PageHero title={"Who we are"} />
      <Content
        bgImg={img}
        pTags={[
          "We are a registered Non-Profit Organization in Kenya, officially recognized by the NGO Co-ordination Board under the Non-Governmental Organizations Act since January 2019. Our journey began as a Community-Based Organization on 10th October 2013, and over time, we evolved into an NGO, empowering us to extend our services to Widows and Orphans across the entire country.",
          ,
          "At Come Together Widows and Orphans Organization, our focus is to champion the protection of human rights and uphold the dignity of widows, in alignment with international standards including the Convention on the Elimination of All Forms of Discrimination against Women (CEDAW) and the Convention on the Rights of the Child. A core aspect of our mandate involves advocating for the resolution of challenges faced by widows in Kenya. ",
        ]}
      />

      <Content
        flexDirection={isMobileSize ? "column" : "row-reverse"}
        bgImg={img2}
        pTags={[
          "To accomplish this, we actively collaborate with the Ministry of Public Service and Gender Affairs and Affirmative Action, effectively engaging the Government to address gender disparities that may contribute to the struggles endured by Widows and Orphans.",
          "We are committed to effecting positive change, fostering empowerment, and creating a more inclusive and equitable society for Widows and Orphans . By working together with both Governmental and Civil Society partners, we aim to bridge gaps, alleviate suffering, and pave the way for a brighter future.",
        ]}
      />
      <Content
        bgImg={img3}
        pTags={[
          "In 2021, we took a proactive role in driving impactful change by leading the development of the Thamini Mjane loan product, a pioneering initiative under the Women Enterprise Fund. This innovative program is specifically designed to extend financial support to widows across the entire country.",
        ]}
      />
    </div>
  );
}
