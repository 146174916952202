import React, { useEffect, useRef, useState } from "react";
import "./Hero.css";
import hero1 from "../../../img/hero1.jpg";
import hero2 from "../../../img/hero2.jpeg";
import hero3 from "../../../img/hero3.jpeg";
import hero4 from "../../../img/education.jpeg";

export default function Hero({ setIsFullBg }) {
  const heroRef = useRef();
  const [frontBgImage, setFrontBgImage] = useState(null);
  const [backBgImage, setBackBgImage] = useState(null);
  const [cardBgImage, setCardBgImage] = useState(null);

  window.addEventListener("scroll", () => {
    if (heroRef.current?.getBoundingClientRect().top < -200) {
      setIsFullBg(true);
    } else {
      setIsFullBg(false);
    }
  });

  useEffect(() => {
    const backgroundImagesList = [hero1, hero2, hero3, hero4];
    let intervalId;

    const getRandomIndexExcluding = (excludeIndex) => {
      let randomIndex;
      do {
        randomIndex = Math.floor(Math.random() * backgroundImagesList.length);
      } while (randomIndex === excludeIndex);
      return randomIndex;
    };

    const flipAndChangeImages = () => {
      const frontRandomIndex = Math.floor(
        Math.random() * backgroundImagesList.length
      );
      const backRandomIndex = getRandomIndexExcluding(frontRandomIndex);
      const cardRandomIndex = getRandomIndexExcluding(frontRandomIndex);

      setFrontBgImage(`url(${backgroundImagesList[frontRandomIndex]})`);
      setBackBgImage(`url(${backgroundImagesList[backRandomIndex]})`);
      setCardBgImage(`url(${backgroundImagesList[cardRandomIndex]})`);
    };

    // Initial flip and image change
    flipAndChangeImages();

    // Set an interval to trigger the flip and image change every 10 seconds
    intervalId = setInterval(() => {
      flipAndChangeImages();
    }, 5000); // 10 seconds in milliseconds

    // Clear the interval when the component is unmounted
    return () => {
      clearInterval(intervalId);
    };
  }, []);

  return (
    <div
      ref={heroRef}
      className="hero"
      style={{ backgroundImage: cardBgImage }}
    >
      <div className="container">
        <div className="content">
          <h1>Come Together Widows and Orphans Organization.</h1>
          <p>
            Champions for the protection of Human Rights and Uphold the Dignity
            of Widows, in alighnment with international standards including the
            Convention on the Elimination of All Forms of Discrimination against
            Women (CEDAW) and the Convention on the Rights of The Child. A core
            aspect of our mandate involves Advocating for the resolution of
            challenges faced by widows in Kenya.
          </p>
        </div>
        {/* <div className="carousel-card">
          <div className="carousel-card-inner">
            <div
              className="carousel-card-front"
              style={{ backgroundImage: frontBgImage }}
            ></div>
            <div
              className="carousel-card-back"
              style={{ backgroundImage: backBgImage }}
            ></div>
          </div>
        </div> */}
      </div>
    </div>
  );
}
