import React from "react";
import PageHero from "../../components/PageHero";
import "./TreePlanting.css";
import img from "../../img/widows-tree.jpeg";
import img1 from "../../img/widows-agri.jpeg";
import img2 from "../../img/hero3.jpeg";
import img3 from "../../img/agri2.jpeg";

export default function TreePlanting() {
  return (
    <div className="tree-planting">
      <PageHero title={"tree planting"} />
      <div className="container">
        <div className="description">
          600,000 tree seedlings. It is a commendable step towards not only
          environmental sustainability but also empowering the widows we
          support. By engaging in this initiative, we believe our organization
          is embodying the synergy between climate action and socio-economic
          upliftment.
        </div>
        <div className="images">
          <div
            className="image"
            style={{ backgroundImage: `url(${img})` }}
          ></div>
          <div
            className="image"
            style={{ backgroundImage: `url(${img1})` }}
          ></div>
          <div
            className="image"
            style={{ backgroundImage: `url(${img2})` }}
          ></div>
          <div
            className="image"
            style={{ backgroundImage: `url(${img3})` }}
          ></div>
        </div>
      </div>
    </div>
  );
}
