import React, { useEffect, useState } from "react";
import "./Achievement.css";
export default function Achievement({ number, description, bgImgs, title }) {
  const [cardBgImage, setCardBgImage] = useState(null);
  useEffect(() => {
    const intervalId = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * bgImgs.length);
      setCardBgImage(bgImgs[randomIndex]);
    }, 2000);

    return () => {
      clearInterval(intervalId);
    };
  }, [bgImgs]);
  return (
    <div className="achievement">
      <div className="container">
        <div
          className="img"
          style={{ backgroundImage: `url(${cardBgImage})` }}
        ></div>
        <div className="detail">
          <div className="number">{number}</div>
          <div className="description">{description}</div>
          <div>
            {title === "widows" ? (
              <>
                <h4>Legal Empowerment:</h4>
                <p>
                  CTWOO focuses on enlightening widows about their rights
                  through legal empowerment initiatives. We conduct basic
                  training sessions on rights and inheritance of properties,
                  culminating in the graduation of widows who successfully
                  complete the training. Widows with individual legal cases
                  receive assistance from our main partner, FIDA Kenya, who
                  provide legal support and representation
                </p>
                <h4>Economic Empowerment:</h4>
                <p>
                  CTWOO's partnership with the Women Enterprise Fund allows
                  widows registered in our groups to apply for and receive
                  funding, boosting their businesses. We have also collaborated
                  with NGAAF to facilitate easy access to funds for widows,
                  further enhancing their economic opportunities. Our internal
                  strategy includes training widows in table banking techniques,
                  promoting savings for sustainability. This initiative has
                  evolved into brook banking, introduced by our partners, the
                  Global Funds for Widows. Additionally, we provide training in
                  various skills to widows, empowering them to generate income
                  independently
                </p>
                <h4>Skills Training and Graduation:</h4>
                <p>
                  CTWOO initiates training programs where widows acquire skills
                  to pursue income-generating activities. Annually, we hold
                  graduation ceremonies for widows who have completed training
                  in different skills, showcasing their achievements and
                  progress.
                </p>
              </>
            ) : (
              <>
                <p>
                  CTWOO supports orphans primarily by providing education at the
                  primary and secondary levels. Our organization goes beyond
                  basic education support; we hold mentorship programs for
                  enrolled orphans, which encompass sexual health training and
                  the provision of dignity kits to ensure their well-being. This
                  mentorship also includes awareness campaigns against Female
                  Genital Mutilation (FGM) in hotspot counties, conducted in
                  collaboration with the Anti-FGM Board, aligning with our
                  commitment to SDG 4 on Quality Education. Moreover, for high
                  school graduates, we facilitate their transition into
                  sustainable livelihoods by enrolling them in programs such as
                  the National Youth Service, achieved through collaboration
                  with the Ministry of Public Service Performance and Delivery
                  Management. These efforts reflect CTWOO's holistic approach to
                  orphan support, ensuring not just education but also
                  comprehensive development opportunities for a brighter future.
                </p>
              </>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
