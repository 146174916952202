import React, { useEffect, useState } from "react";
import Content from "../../components/Content";
import PageHero from "../../components/PageHero";
import "./Declaration.css";
import img from "../../img/widows-creed.jpeg";
import img2 from "../../img/church.jpeg";

export default function Declaration() {
  const [isMobileSize, setIsMobileSize] = useState(false);
  useEffect(() => {
    if (window.innerWidth < 992) {
      setIsMobileSize(true);
    } else {
      setIsMobileSize(false);
    }
  }, []);
  return (
    <div>
      <PageHero title={"declaration of beliefs"} />
      <Content
        bgImg={img2}
        flexDirection={isMobileSize ? "column" : "row-reverse"}
        pTags={[
          "In the tapestry of Kenya's rich diversity, woven together by the threads of various faiths, we, the collective heart of the 'Come Together Widows and Orphans Organization,' stand united in our unwavering commitment to compassion, justice, and healing. Embracing the values that each religion imparts, we are bound by a shared belief that transcends denominations and creeds, to uplift the lives of widows and orphans who have endured the shadows of gender-based violence and loss.",
        ]}
      />
      <Content
        bgImg={img}
        pTags={[
          "Drawing from the teachings of Islam, Christianity, Hinduism, and every spiritual path that graces our nation, we recognize the sanctity of all life and the imperative to stand as guardians of the vulnerable. Our resolve stems from the understanding that the core tenets of love, empathy, and respect, found within every faith, converge to form a radiant mosaic of hope for the Widows, Survivors of Gender Based Violence and Orphans.",

          " At Come Together Widows and Orphans Organization, we embrace the sacred wisdom woven within the fabric of Kenya's diverse religious tapestry. Recognizing the essence of compassion, justice, and empathy shared by every faith, we find unity in our commitment to uphold the rights of widows, guided by verses that resonate across religious boundaries",
        ]}
      />
    </div>
  );
}
