import React from "react";
import "./Content.css";

export default function Content({ flexDirection, pTags, bgImg }) {
  return (
    <div className="Content">
      <div
        className="content-container"
        style={{ flexDirection: flexDirection }}
      >
        <div
          className="content-img"
          style={{ backgroundImage: `url(${bgImg})` }}
        ></div>
        <div className="content-content">
          {pTags?.map((item, index) => (
            <p key={index}>{item}</p>
          ))}
        </div>
      </div>
    </div>
  );
}
