import React, { useState } from "react";
import Achievement from "../../../components/Achievement";
import "./Achievements.css";
import bgimg1 from "../../../img/achievements/widows/0.jpeg";
import bgimg2 from "../../../img/achievements/widows/1.jpeg";
import bgimg3 from "../../../img/achievements/widows/2.jpeg";
import bgimg4 from "../../../img/achievements/widows/3.jpeg";
import bgimg5 from "../../../img/achievements/widows/4.jpeg";
import bgimg10 from "../../../img/achievements/widows/5.jpeg";

import bgimg6 from "../../../img/achievements/orphans/0.jpeg";
import bgimg7 from "../../../img/achievements/orphans/1.jpeg";
import bgimg8 from "../../../img/achievements/orphans/2.jpeg";
import bgimg9 from "../../../img/achievements/orphans/4.jpeg";

export default function Achievements() {
  const bgImages = {
    widow: {
      img: [bgimg1, bgimg2, bgimg3, bgimg4, bgimg5, bgimg10],
    },
    oprhans: { img: [bgimg6, bgimg7, bgimg8, bgimg9] },
  };
  return (
    <div className="achievements">
      <h2 className="title">Our Achievements</h2>
      <div className="container">
        <Achievement
          number={"771,395"}
          description={"WIDOWS SUPPORTED"}
          bgImgs={bgImages.widow.img}
          title={"widows"}
        />
        <Achievement
          number={"16,782"}
          description={"ORPHANS REACHED"}
          bgImgs={bgImages.oprhans.img}
          info={"orphans"}
        />
      </div>
    </div>
  );
}
