import React, { useEffect, useState } from "react";
import PageHero from "../../components/PageHero";
import "./Agriculture.css";
import img from "../../img/widows-agri.jpeg";
import img1 from "../../img/agri1.jpeg";
import img3 from "../../img/agri2.jpeg";
import img4 from "../../img/widows-tree.jpeg";
import img2 from "../../img/widows-agri.jpeg";

export default function Agriculture() {
  const [isMobileSize, setIsMobileSize] = useState(false);
  useEffect(() => {
    if (window.innerWidth < 992) {
      setIsMobileSize(true);
    } else {
      setIsMobileSize(false);
    }
  }, []);
  return (
    <div className="agriculture">
      <PageHero title={"Agriculture"} />
      <div className="Content">
        <div className="content-container">
          <div
            className="content-img"
            style={{ backgroundImage: `url(${img1})` }}
          ></div>
          <div className="content-content">
            <p>
              We believe in Agriculture and Food Security a vital component of
              our commitment to empowering widows in agriculture. Through this
              initiative, we wholeheartedly support widows engaged in crop
              farming and animal rearing, fostering self-reliance and
              sustainability. Our primary goal is to alleviate poverty and
              eradicate hunger, aligning with the Sustainable Development Goals
              1 and 2. By nurturing widows' involvement in agriculture, we
              create a meaningful source of income while contributing to a world
              with reduced poverty and zero hunger. We focus in transforming
              live of widows and their children through agricultural
              empowerment, hence cultivating a brighter future for them and the
              generations to come
            </p>
          </div>
        </div>
      </div>
      <div className="images">
        <div className="img1" style={{ backgroundImage: `url(${img2})` }}></div>
        <div className="img2" style={{ backgroundImage: `url(${img3})` }}></div>
        <div className="img3" style={{ backgroundImage: `url(${img4})` }}></div>
      </div>
    </div>
  );
}
