import React, { useEffect, useState } from "react";
import PageHero from "../../components/PageHero";
import "./Health.css";
import img from "../../img/widows-health.jpeg";

export default function Health() {
  const [isMobileSize, setIsMobileSize] = useState(false);
  useEffect(() => {
    if (window.innerWidth < 992) {
      setIsMobileSize(true);
    } else {
      setIsMobileSize(false);
    }
  }, []);
  return (
    <div className="health">
      <PageHero title={"Health"} />
      <div className="container">
        <div className="content top-text">
          <p>
            At Come Together Widows and Orphans Organization, our commitment to
            supporting widows and their children extends to the crucial area of
            health, with a particular focus on those living with HIV/AIDS and
            CANCER. Recognizing the unique challenges they face, our
            organization is dedicated to providing holistic assistance and
            empowerment in the following ways;
          </p>
          <p>
            Psychosocial Support: Coping with HIV/AIDS or CANCER, takes a toll
            on mental and emotional well-being. We offer counselling services
            and support groups tailored to the needs of widows and their
            families. These platforms provide a safe space for sharing
            experiences, reducing stigma, and fostering a sense of belonging.
          </p>
        </div>
        <div className="video-content">
          <iframe
            src="https://www.youtube.com/embed/q3KNDU-JI6I?si=R-KiyZAFagfN-hwU"
            title="YouTube video player"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            allowFullScreen
          ></iframe>
        </div>
        <div className="content bottom-text">
          <p>
            Health Education and Awareness: We prioritie educating widows living
            with HIV/AIDS, CANCER and their children about the importance of
            proper health management, adherence to medication, and adopting
            healthy lifestyles. Through workshops, seminars, and informational
            campaigns, we empower them with the knowledge necessary to make
            informed health decisions.
          </p>
          <p>
            Nutrition and Well-being: A balanced diet is crucial for managing
            HIV/AIDS and CANCER as well. We provide nutritional guidance and
            support to help widows and their children maintain optimal health.
            Through partnerships with nutritionists and community initiatives,
            we strive to ensure that they have access to nutritious food.
            Occasionally, we provide widows who are victims with balanced diet
            food packages.
          </p>
        </div>
      </div>
    </div>
  );
}
