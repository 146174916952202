import React from "react";

import Achievements from "./sections/Achievements";
import Hero from "./sections/Hero";
import NewsStories from "./sections/NewsStories";
import WhatWeDo from "../home/sections/WhatWeDo";
import Programmes from "./sections/Programmes";
import Organogram from "./sections/Organogram";

export default function Home({ setIsFullBg }) {
  return (
    <div>
      <Hero setIsFullBg={setIsFullBg} />
      <WhatWeDo />
      <Achievements />
      <Organogram />
      <Programmes />
      <NewsStories />
      {/* partners */}
    </div>
  );
}
