import React, { useEffect, useState } from "react";
import PageHero from "../../components/PageHero";
import "./Economic.css";
import img from "../../img/widows-biz.jpeg";
import img2 from "../../img/widows-skills.jpeg";
import img3 from "../../img/hero3.jpeg";
import img4 from "../../img/agri2.jpeg";

export default function Economic() {
  const [isMobileSize, setIsMobileSize] = useState(false);
  useEffect(() => {
    if (window.innerWidth < 992) {
      setIsMobileSize(true);
    } else {
      setIsMobileSize(false);
    }
  }, []);
  return (
    <div className="economic">
      <PageHero title={"Economic Empowerment"} />

      <div className="Content">
        <div
          className="content-container"
          style={
            isMobileSize
              ? { flexDirection: "column" }
              : { flexDirection: "row-reverse" }
          }
        >
          <div
            className="content-img"
            style={{ backgroundImage: `url(${img})` }}
          ></div>
          <div className="content-content">
            <p>
              Having fortified the widows through comprehensive legal aid, we
              embark on the next chapter of our focus: economic empowerment for
              widows. As we transition from upholding their rights within the
              legal realm to fostering their self-sufficiency and prosperity, we
              recognize that legal empowerment is but the initial step towards a
              journey of holistic transformation.,
            </p>
          </div>
        </div>
      </div>
      <div className="Content">
        <div className="content-container">
          <div
            className="content-img"
            style={{ backgroundImage: `url(${img2})` }}
          ></div>
          <div className="content-content">
            <p>
              Economic empowerment stands as the natural progression, building
              upon the newfound agency that legal aid provides. It encompasses a
              spectrum of initiatives tailored to enable widows to not only
              secure their livelihoods but also to thrive as active contributors
              to their families and communities. At Come Together Widows and
              Orphans we empower widows economically through;
            </p>
          </div>
        </div>
      </div>
      <div className="Content">
        <div
          className="content-container"
          style={
            isMobileSize
              ? { flexDirection: "column" }
              : { flexDirection: "row-reverse" }
          }
        >
          <div
            className="content-img"
            style={{ backgroundImage: `url(${img3})` }}
          ></div>
          <div className="content-content">
            <p>
              WISALA - the Widows Savings and Loans Association, a
              transformative table banking strategy that we are actively
              implementing within widows groups to bolster economic
              sustainability. Through a strategic alliance with the Global Funds
              for Widows, we synergize resources, co-investing in widows'
              initial contributions. This partnership not only amplifies their
              initial savings but also serves to provide them with essential
              business capital, catalyzing their entrepreneurial endeavours.
              Additionally, this collaboration empowers members to access soft
              loans, further fortifying their financial independence and
              equipping them with the tools to navigate their economic journeys
              with confidence.
            </p>
          </div>
        </div>
      </div>
      <div className="Content">
        <div className="content-container">
          <div
            className="content-img"
            style={{ backgroundImage: `url(${img4})` }}
          ></div>
          <div className="content-content">
            <p>
              SAGAS- Semi Autonomous Government Agencies, encompassing pivotal
              entities including Women Enterprise Fund, Thamini Mjane, Uwezo
              Fund, and the National Government Affirmative Action Fund (NGAAF).
              Our approach involves the dynamic process of mobilizing widows,
              uniting them into small, cohesive working groups that subsequently
              become registered under the guidance of the Social Protection
              office. Through a collaborative effort involving the Ministry of
              Public Service, Gender Affairs, and Affirmative Action, these
              registered widows gain access to comprehensive financial literacy
              training and secure avenues to access the diverse funding
              opportunities offered by the aforementioned agencies.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
