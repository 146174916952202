import React from "react";
import bgImage from "../../../img/organo-diagram.jpg";

export default function Organogram() {
  return (
    <div style={styles.container}>
      <h2 className="title" style={{ marginBottom: "2vh" }}>
        organization's summary diagram
      </h2>
      <div style={styles.content}></div>
    </div>
  );
}
const styles = {
  container: {
    textAlign: "center",
    marginBottom: "10vh",
  },
  content: {
    backgroundSize: "contain",
    backgroundPosition: "center",
    width: "100vw",
    height: "60vh",
    backgroundRepeat: "no-repeat",
    backgroundImage: `url(${bgImage})`,
  },
};
