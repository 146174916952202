import React, { useEffect, useState } from "react";
import Content from "../../components/Content";
import PageHero from "../../components/PageHero";
import "./ChildrenVisit.css";

import visits from "../../img/visits.jpeg";
import visits1 from "../../img/cv1.jpeg";
import visits2 from "../../img/cv2.jpeg";

export default function ChildrenVisit() {
  const [isMobileSize, setIsMobileSize] = useState(false);
  useEffect(() => {
    if (window.innerWidth < 992) {
      setIsMobileSize(true);
    } else {
      setIsMobileSize(false);
    }
  }, []);
  return (
    <div className="children-visit">
      <PageHero title={"Children Visit"} />

      <div className="Content">
        <div
          className="content-container"
          style={
            isMobileSize
              ? { flexDirection: "column" }
              : { flexDirection: "row-reverse" }
          }
        >
          <div className="content-img">
            <iframe
              src="https://www.youtube.com/embed/KXNBpyA6wcQ?si=O1wccvqs8WRBQ5v9"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
          <div className="content-content">
            <p>
              We understand the importance of creating connections and a sense
              of belonging. As part of our Corporate Social Responsibility
              (CSR), we organize regular visits for orphaned and vulnerable
              children to interact with peers in orphanages and children's
              homes. These interactions foster a spirit of unity, empathy, and
              solidarity among children facing similar circumstances.
            </p>
            <p>
              Join us in our endeavour to uplift orphaned and vulnerable
              children, nurture their potential, and brighten their paths ahead.
              Together, we're creating a future filled with hope, opportunity,
              and compassionate communities
            </p>
          </div>
        </div>
      </div>

      <div className="images">
        <div
          className="img1"
          style={{ backgroundImage: `url(${visits})` }}
        ></div>
        <div
          className="img2"
          style={{ backgroundImage: `url(${visits1})` }}
        ></div>
        <div
          className="img3"
          style={{ backgroundImage: `url(${visits2})` }}
        ></div>
      </div>
    </div>
  );
}
