import React from "react";
import PageHero from "../../components/PageHero";
import "./OurPartners.css";
import GalleryCard from "../../components/GalleryCard";

import img1 from "../../img/partner1.jpeg";
import img2 from "../../img/partner2.jpeg";
import img3 from "../../img/partner3.jpeg";
// import img4 from "../../img/partner4.jpeg";
import img5 from "../../img/partner5.jpeg";
import img6 from "../../img/partner6.jpeg";
import img7 from "../../img/partner7.jpeg";
import img8 from "../../img/partner8.jpeg";
import img9 from "../../img/partner9.jpeg";
import img10 from "../../img/partner10.jpeg";
import img11 from "../../img/partner11.jpeg";
import img12 from "../../img/partner12.jpeg";
import img13 from "../../img/partner13.jpeg";

export default function OurPartners() {
  const teamData = [
    {
      img: img1,
      name: "name name",
    },
    {
      img: img2,
      name: "name name",
    },
    {
      img: img3,
      name: "name name",
    },
    // {
    //   img: img4,
    //   name: "name name",
    // },
    {
      img: img5,
      name: "name name",
    },
    {
      img: img6,
      name: "name name",
    },
    {
      img: img1,
      name: "name name",
    },
    {
      img: img7,
      name: "name name",
    },
    {
      img: img8,
      name: "name name",
    },
    {
      img: img9,
      name: "name name",
    },
    {
      img: img10,
      name: "name name",
    },
    {
      img: img11,
      name: "name name",
    },
    {
      img: img12,
      name: "name name",
    },
    {
      img: img13,
      name: "name name",
    },
  ];
  return (
    <div className="our-partners">
      <PageHero title={"our partners"} />
      <div className="container">
        {teamData.map((item, index) => (
          <GalleryCard key={index} bgImg={item.img} name={item.name} />
        ))}
      </div>
    </div>
  );
}
