import React, { useState } from "react";
import NewsCard from "../../../components/NewsCard";
import "./NewsStories.css";
import img1 from "../../../img/news1.png";

import img2a from "../../../img/news/1/news1.jpeg";
import img2b from "../../../img/news/1/news2.jpeg";
import img2c from "../../../img/news/1/news3.jpeg";
import img2d from "../../../img/news/1/news4.jpeg";

import img5a from "../../../img/news/4/news1.jpeg";
import img5b from "../../../img/news/4/news2.jpeg";
import img5c from "../../../img/news/4/news3.jpeg";

import img7a from "../../../img/news/6/news1.jpeg";
import img7b from "../../../img/news/6/news2.jpeg";
import img7c from "../../../img/news/6/news3.jpeg";

// import img8a from "../../../img/news/7/0.jpeg";
// import img8b from "../../../img/news/7/1.jpeg";
// import img8c from "../../../img/news/7/2.jpeg";
// import img8d from "../../../img/news/7/3.jpeg";
// import img8e from "../../../img/news/7/4.jpeg";
// import img8f from "../../../img/news/7/5.jpeg";
// import img8g from "../../../img/news/7/6.jpeg";
// import img8h from "../../../img/news/7/7.jpeg";
// import img8i from "../../../img/news/7/8.jpeg";

import img3 from "../../../img/news3.png";
import img4 from "../../../img/zetech.jpeg";

export default function NewsStories() {
  const [currentPage, setCurrentPage] = useState(0);

  const newsData = [
    // {
    //   topic: "Popup 0 to 16 days",
    //   headline:
    //     "We make it our mission to End Violence Against Widowed Women and their children through our Annual participation in the 16 Days of Activism Global Campaign where we tirelessly champion for the Rights and Dignity of widows and their children across various counties. To culminate this campaign, we always wrap it up with a Goat-Giving Ceremony in Samburu County in a heartwarming Goat-Giving Ceremony for Widows and Widowers. This goat-giving ceremony symbolizes sustenance, prosperity, and the enduring bonds of solidarity. By including widowers, we strive for inclusivity, recognizing the multifaceted challenges faced by all those left behind by loss. We do this to ensure we sow the seeds of change to help us in promoting a brighter future where every Widowed Person can live a dignified and fulfilled life.",
    //   bgImgs: [img8a, img8b, img8c, img8d, img8e, img8f, img8g, img8h, img8i],
    // },
    {
      topic: "legal framework at wajir county",
      headline:
        "In Wajir County, Kenya, widows often faced unique legal challenges, but Come Together Widows and Orphans, the organization, was committed to their empowerment through a robust legal framework. Kenyan law recognized the rights of widows, and our organization was dedicated to ensuring that these rights were upheld and protected in Wajir County",
      bgImgs: [img7a, img7b, img7c],
    },
    // {
    //   topic:
    //     "cometogether widows and orphans organisation participation in the 23rd celebration of UNSCR 1325 in elgeyo marakwet",
    //   headline:
    //     "Come Together Widows and Orphans Organization, dedicated to supporting widows across all 47 counties, continues its commitment to empowering women in conflict-affected regions.",
    //   bgImgs: [img4],
    // },
    {
      topic: "celebration of the international day of the girl child.",
      headline:
        "Come Together Widows and Orphans Organization, in a monumental celebration in Garrissa County, commemorated the International Day of the Girl Child with an impactful mentorship program at Umi Salama Girls High School.",
      bgImgs: [img5a, img5b, img5c],
    },
    {
      topic: "Career Affair in Zetech University",
      headline:
        "We are honored to be participating in the #CareerFair2023 at #ZetechUniversity Ruiru Campus. We are grateful to Zetech University who are our great partners who have always entrusted us with their Students whenever they are in need of attachment.",
      bgImgs: [img4],
    },
    {
      topic: "CTWOO PRE-VALENTINES DINNER 2023",
      headline:
        "Valentine’s Day is a time to celebrate love and affection, but it’s also an opportunity to give back to those in need. This year, the Come Together Widows and Orphans Organization is hosting a Pre-Valentine Dinner to raise funds to support widows and orphans in our community. The…",
      bgImgs: [img1],
    },
    {
      topic: "16 DAYS OF ACTIVISM EVENTS- 2022",
      headline:
        "We make it our mission to End Violence Against Widowed Women and their children through our Annual participation in the 16 Days of Activism Global Campaign where we tirelessly champion for the Rights and Dignity of widows and their children across various counties. To culminate this campaign, we always wrap it up with a Goat-Giving Ceremony in Samburu County in a heartwarming Goat-Giving Ceremony for Widows and Widowers. This goat-giving ceremony symbolizes sustenance, prosperity, and the enduring bonds of solidarity. By including widowers, we strive for inclusivity, recognizing the multifaceted challenges faced by all those left behind by loss. We do this to ensure we sow the seeds of change to help us in promoting a brighter future where every Widowed Person can live a dignified and fulfilled life.",
      bgImgs: [img2a, img2b, img2c, img2d],
    },
    {
      topic: "Stakeholder Consultative Workshop",
      headline:
        "On 7/11/2022 Cometogether widows and Orphans Founder Ms. Dianah Kamande HSC attended a Stakeholder Consultative Workshop towards the attainment of ‘A Dignified Future: The Future for Vulnerable Youth and Populations’ which is the agenda of the Office of the Spouse of the Deputy President 2022-2027. According to the…",
      bgImgs: [img3],
    },
  ];
  const itemsPerPage = 4;
  const totalPages = Math.ceil(newsData.length / itemsPerPage);

  const handleNextPage = () => {
    setCurrentPage((prevPage) => (prevPage + 1) % totalPages);
  };

  return (
    <div className="news-stories">
      <h3 className="title">News & Stories</h3>
      <div className="container">
        {newsData
          .slice(currentPage * itemsPerPage, (currentPage + 1) * itemsPerPage)
          .map((news, index) => (
            <NewsCard
              key={index}
              topic={news.topic}
              headline={news.headline}
              bgImgs={news.bgImgs}
            />
          ))}
      </div>
      <button onClick={handleNextPage}>{`${
        currentPage === 0 ? "More" : "Back"
      }`}</button>
    </div>
  );
}
