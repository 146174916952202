import React, { useEffect, useState } from "react";
import PageHero from "../../components/PageHero";
import "./BeeKeeping.css";
import img from "../../img/bee.jpeg";

export default function BeeKeeping() {
  const [isMobileSize, setIsMobileSize] = useState(false);
  console.log(isMobileSize);
  useEffect(() => {
    window.addEventListener("resize", () => {
      if (window.innerWidth < 992) {
        setIsMobileSize(true);
      } else {
        setIsMobileSize(false);
      }
    });
    if (window.innerWidth < 992) {
      setIsMobileSize(true);
    } else {
      setIsMobileSize(false);
    }
  }, []);
  return (
    <div className="bee-keeping">
      <PageHero title={"Bee Keeping"} />
      <div className="Content">
        <div className="content-container">
          <div
            className="content-img"
            style={{ backgroundImage: `url(${img})` }}
          ></div>
          <div className="content-content">
            <p>
              Come Together Widows and Orphans Organization has introduced
              beekeeping project among widows in Kajiado County as part of our
              initiative reflecting a forward thinking approach that aims to
              embrace dual benefits of climate change mitigation and
              social-economic empowerment for widows and their families. As Come
              Together Widows and Orphans Organization, we aim the following as
              a result of initiating beekeeping project.
            </p>
            <p>
              Pollinator Conservation: Bees play a crucial role in pollination,
              which is essential for the reproduction of many plants, including
              crops. Climate change disrupts pollination patterns, affecting
              food security and biodiversity. Our beekeeping project therefore
              contributes to pollinator conservation, helping to counteract
              these negative effects.
            </p>
          </div>
        </div>
      </div>
      <div className="Content">
        <div
          className="content-container"
          style={
            isMobileSize
              ? { flexDirection: "column" }
              : { flexDirection: "row-reverse" }
          }
        >
          <div
            className="content-img"
            style={{ backgroundImage: `url(${img})` }}
          ></div>
          <div className="content-content">
            <p>
              Biodiversity Enhancement: Beekeeping fosters biodiversity by
              providing a habitat for bees and other pollinators. As habitats
              are lost due to climate change, creating safe spaces for
              pollinators becomes vital for maintaining a diverse ecosystem.
            </p>
            <p>
              Carbon Sequestration: Trees and plants that bees pollinate are
              essential for carbon sequestration, as they absorb carbon dioxide
              from the atmosphere. By supporting beekeeping, we indirectly
              contribute to the fight against climate change by aiding in carbon
              capture.
            </p>
          </div>
        </div>
      </div>
      <div className="Content">
        <div className="content-container">
          <div
            className="content-img"
            style={{ backgroundImage: `url(${img})` }}
          ></div>
          <div className="content-content">
            <p>
              Diversified Livelihoods: Beekeeping offers a diversified source of
              income for widows. The sale of honey and other bee-related
              products provides them with a stable income, reducing their
              vulnerability to climate-induced economic shocks.
            </p>
            <p>
              It’s our belief that by incorporating beekeeping into our
              organization's efforts, we're not only addressing climate change
              but also providing widows with a sustainable means of income and
              empowerment. This project aligns perfectly with our commitment to
              creating positive change in both environmental and socio-economic
              spheres.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
