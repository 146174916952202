import React from "react";
import PageHero from "../../components/PageHero";
import "./WhatWeDoPage.css";

export default function WhatWeDoPage() {
  return (
    <div className="what-we-do-page">
      <PageHero title={"what we do"} />
      <div className="content-container">
        <div className="container">
          <div className="div">
            <h3>
              Our Impactful Initiatives: Empowering Widows across the Nation
            </h3>
            <p className="intro">
              CTWOO main aim is to break the poverty chain that revolves around
              widows in Kenya and see that widows have the capability to empower
              each other economically, socially, and financially. The main
              strategy that has been used for the longest time, is table banking
              for widows that has finally attracted Global Funds for Widows who
              has become main partners in this cause of economic and social
              empowerment. CTWOO has created a space where widows can
              comfortably share their experiences, problems and challenges they
              are facing as exposed to them by the society, family members,
              churches or even friends.
            </p>
            <p className="intro">
              Come Together widows and orphans organization holds social
              gatherings to celebrate different days that are meant to celebrate
              them. This has finally attracted partners like FIDA Kenya,
              Commission on Administrative justice, Kenya Land Alliance who aid
              in providing the necessary skills to widows in the context of
              legal and rights protection.
            </p>
            <p className="intro">
              CTWOO also works with Office of Women representatives in County
              levels, Government Ministries, Departments and Agencies to support
              the implementation of the frameworks and policies that protect
              widows in Kenya inclusive of their rights as individuals as well
              as their property rights that contribute significantly towards
              reducing the already existing gender gap. CTWOO direct
              beneficiaries who in case are widows appears to be the closest
              people whom they collaborate with. As an Organization; widows
              groups make it crucial and simple for the implementation of
              different initiatives outlined in the CTWOO Programs. CTWOO makes
              it a priority in guiding the widows on the formation of small
              working groups after successful journey of healing from loss and
              grieving.
            </p>
          </div>
          {/* <div className="activities">
            <div className="left">
              <div className="activity">
                <h3>Mobilization and formation of widows groups</h3>
                <p>
                  We take the first step in empowering widows by mobilizing them
                  and facilitating the formation of small working groups. By
                  uniting widows, we create a supportive network that fosters
                  solidarity, shared experiences, and mutual encouragement.
                  Through these groups, widows collectively address challenges,
                  share resources, and build a stronger sense of community.
                </p>
              </div>
              <div className="activity">
                <h3>Guidance on registration with social protection offices</h3>
                <p>
                  We guide widows through the process of registering with Social
                  Protection Offices. This step is crucial in ensuring that
                  widows have access to Government Affirmative Funds and other
                  services that provide them essential support during times of
                  need. Our commitment to facilitating this registration
                  empowers widows to avail themselves of the resources and aid
                  available to them.
                </p>
              </div>
            </div>
            <div className="activity">
              <h3>
                Provision of legal aid with an aim of overcoming loss and grief
              </h3>
              <p>
                Loss and grief are significant challenges that widows often
                face. We stand beside widows, offering them legal aid and
                emotional support to navigate these difficult times. Our
                dedicated efforts are aimed at helping widows overcome legal
                hurdles and emotional obstacles, empowering them to move forward
                with strength and resilience.
              </p>
            </div>
            <div className="right">
              <div className="activity">
                <h3>Widows economic empowerment for sustainability</h3>
                <p>
                  Beyond emotional support, we recognize the importance of
                  economic empowerment in ensuring the long-term sustainability
                  of widows' lives. We provide widows with the tools, resources,
                  and training they need to become economically self-reliant. By
                  fostering entrepreneurship and financial independence, we
                  empower widows to create brighter futures for themselves and
                  their families.
                </p>
              </div>
              <div className="activity">
                <h3>Widows empowerment through climate action integration</h3>
                <p>
                  implementation of climate problem-solving projects in Kenya,
                  thereby creating a more inclusive and sustainable approach to
                  addressing environmental challenges. At Come Together Widows
                  and Orphans Organization, we are dedicated to creating a
                  holistic approach to empowerment that addresses widows'
                  emotional well-being and economic security. Through our
                  multifaceted initiatives, we are working diligently to make a
                  lasting difference in the lives of widows across the nation.
                </p>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}
