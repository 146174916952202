import React, { useEffect, useState } from "react";
import PageHero from "../../components/PageHero";
import "./OurBoard.css";
import Content from "../../components/Content";
import diana_img from "../../img/diana.jpeg";
import TeamCard from "../../components/TeamCard";

import alex from "../../img/alex.png";
import george from "../../img/george.png";
import beth from "../../img/beth.jpeg";
import fredrick from "../../img/fredrick.png";
import peter from "../../img/peter.png";
import cherly from "../../img/cheryl.jpeg";
import delvin from "../../img/delvin.jpeg";

export default function OurBoard() {
  const [isMobileSize, setIsMobileSize] = useState(false);
  useEffect(() => {
    if (window.innerWidth < 992) {
      setIsMobileSize(true);
    } else {
      setIsMobileSize(false);
    }
  }, []);

  const teamData = [
    {
      img: fredrick,
      name: "Fredrick Onyambu",
      role: "Programme Manager",
      bio: "lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem",
    },

    {
      img: beth,
      name: "Beth Wanjiku",
      role: "Field Officer",
      bio: "lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem",
    },
    {
      img: peter,
      name: "Peter Musau",
      role: "Field Officer",
      bio: "lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem",
    },
    {
      img: cherly,
      name: "Sheryl Otieno",
      role: "Program Department",
      bio: "lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem",
    },
    {
      img: delvin,
      name: "Delvin Owour",
      role: "Field Officer",
      bio: "lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem",
    },
    {
      img: george,
      name: "George Ekokwa",
      role: "Media Personnel",
      bio: "lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem",
    },
    {
      img: alex,
      name: "Alex Kiogora",
      role: "Media Personnel",
      bio: "lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem lorem",
    },
  ];
  return (
    <div className="our-board">
      <PageHero title={"Our Team"} />
      <Content
        flexDirection={isMobileSize ? "column" : "row-reverse"}
        pTags={[
          "Dianah Kamande, Founder & Executive Director,  is a survivor of Gender Violence, specifically Domestic Violence that ushered her into widowhood.",
          "She is a trainer on Women in Leadership and Governance in Africa and a celebrated widow, Gender Violence and Orphans rights champion. She has mentored many widow leaders and her work has been replicated in all the 47 counties of Kenya, among other African countries. She is also the founder of Association of Survivors of Gender Based Violence.",
          "She holds a Diploma in Women in Leadership and Governance in Africa from Nairobi University, and Barchelors degree in Governance, Peace and Conflict Studies from African Nazarene University. An award winner of Above and Beyond Awards in Boston, USA. Awarded for her Exemplary leadership and Advocacy on widows matters by the Global Fund for Widows in New York, USA",
        ]}
        bgImg={diana_img}
      />
      <div className="container">
        {teamData.map((item, index) => (
          <TeamCard
            key={index}
            teamImg={item.img}
            name={item.name}
            role={item.role}
            bio={item.bio}
          />
        ))}
      </div>
    </div>
  );
}
