import React from "react";
import PageHero from "../../components/PageHero";
import "./OurStaff.css";

export default function OurStaff() {
  return (
    <div>
      <PageHero title={"our staff"} />
    </div>
  );
}
