import "./App.css";
import { BrowserRouter, Routes, Route, useLocation } from "react-router-dom";
import Home from "./pages/home/Home";
import CountyChapters from "./pages/about/CountyChapters";
import Declaration from "./pages/about/Declaration";
import OurBoard from "./pages/about/OurBoard";
import OurFunders from "./pages/about/OurFunders";
import OurPartners from "./pages/about/OurPartners";
import OurStaff from "./pages/about/OurStaff";
import WhatWeDoPage from "./pages/about/WhatWeDoPage";
import WhoWeAre from "./pages/about/WhoWeAre";
import Agriculture from "./pages/climate/Agriculture";
import BeeKeeping from "./pages/climate/BeeKeeping";
import Humanitarian from "./pages/climate/Humanitarian";
import TreePlanting from "./pages/climate/TreePlanting";
import ChildrenVisit from "./pages/orphans/ChildrenVisit";
import Education from "./pages/orphans/Education";
import Mentorship from "./pages/orphans/Mentorship";
import Economic from "./pages/widows/Economic";
import GBV from "./pages/widows/GBV";
import Health from "./pages/widows/Health";
import LegalAid from "./pages/widows/LegalAid";
import SkillsTraining from "./pages/widows/SkillsTraining";
import Donate from "./pages/donate/Donate";
import Gallery from "./pages/gallery/Gallery";
import Header from "./components/Header";
import Footer from "./components/Footer";
import { useEffect, useState } from "react";
import ClimateAction from "./pages/widows/ClimateAction";
import Report from "./pages/reports/Report";

function App() {
  const [isFullBg, setIsFullBg] = useState(false);

  function ScrollToTopOnRouteChange() {
    const location = useLocation();

    useEffect(() => {
      const scrollToTop = () => {
        const scrollStep = window.scrollY / 50;

        const scrollInterval = setInterval(() => {
          if (window.scrollY === 0) {
            clearInterval(scrollInterval);
          } else {
            window.scrollBy(0, -scrollStep);
          }
        }, 15); // Adjust the interval for smoother/faster scrolling
      };

      if (location.pathname !== "/") {
        scrollToTop();
      }
    }, [location]);

    return null;
  }

  return (
    <div className="App">
      <BrowserRouter>
        <Header isFullBg={isFullBg} />
        <ScrollToTopOnRouteChange />

        <Routes>
          <Route path="/donate" element={<Donate />} />
          <Route path="/gallery" element={<Gallery />} />
          <Route index path="/" element={<Home setIsFullBg={setIsFullBg} />} />
          <Route path="/county-chapters" element={<CountyChapters />} />
          <Route path="/declaration-of-beliefs" element={<Declaration />} />
          <Route path="/our-board" element={<OurBoard />} />
          <Route path="/our-funders" element={<OurFunders />} />
          <Route path="/our-partners" element={<OurPartners />} />
          <Route path="/our-staff" element={<OurStaff />} />
          <Route path="/what-we-do" element={<WhatWeDoPage />} />
          <Route path="/who-we-are" element={<WhoWeAre />} />
          <Route path="/agriculture-and-food" element={<Agriculture />} />
          <Route path="/bee-keeping" element={<BeeKeeping />} />
          <Route
            path="/humanitarian-crisis-management"
            element={<Humanitarian />}
          />
          <Route path="/tree-planting" element={<TreePlanting />} />
          <Route path="/children-visits" element={<ChildrenVisit />} />
          <Route path="/education-support" element={<Education />} />
          <Route path="/mentorship-programme" element={<Mentorship />} />
          <Route path="/economic-and-empowerment" element={<Economic />} />
          <Route path="/gender-based-violence" element={<GBV />} />
          <Route path="/health" element={<Health />} />
          <Route path="/legal-aid" element={<LegalAid />} />
          <Route path="/skills-training" element={<SkillsTraining />} />
          <Route path="/report" element={<Report />} />
          <Route path="/climate-action" element={<ClimateAction />} />
        </Routes>
        <Footer />
      </BrowserRouter>
    </div>
  );
}

export default App;
