import React from "react";
import GalleryCard from "../../components/GalleryCard";
import PageHero from "../../components/PageHero";
import "./Gallery.css";

import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import TwitterIcon from "@mui/icons-material/Twitter";
import LinkedInIcon from "@mui/icons-material/LinkedIn";

import img1 from "../../img/hero2.jpeg";
import img2 from "../../img/widows-law.jpeg";
import img3 from "../../img/widows.jpeg";
import img4 from "../../img/visits.jpeg";
import img5 from "../../img/whoweare.jpeg";
import img6 from "../../img/widow.jpg";
import img7 from "../../img/widows-agri.jpeg";
import img8 from "../../img/widows-health.jpeg";
import img9 from "../../img/widows-climate.jpeg";
import img10 from "../../img/widows-gbv.jpeg";
import img11 from "../../img/widows-human.jpeg";
import img12 from "../../img/widows-creed.jpeg";
import img13 from "../../img/widows-biz.jpeg";
import img14 from "../../img/widows-law.jpeg";
import img15 from "../../img/widows.jpeg";
import img16 from "../../img/whatwedohome.jpeg";
import img17 from "../../img/widows-tree.jpeg";
import img18 from "../../img/visits.jpeg";
import img19 from "../../img/widows-skills.jpeg";
import img20 from "../../img/295499347_2064951873706913_5635486518465081226_n.jpg";
import img21 from "../../img/news/7/0.jpeg";
import img22 from "../../img/news/7/1.jpeg";
import img23 from "../../img/news/7/2.jpeg";
import img24 from "../../img/news/7/3.jpeg";
import img25 from "../../img/news/7/4.jpeg";
import img26 from "../../img/news/7/5.jpeg";
import img27 from "../../img/news/7/6.jpeg";
import img28 from "../../img/news/7/7.jpeg";
import img29 from "../../img/news/7/8.jpeg";

export default function Gallery() {
  const galleryData = [
    {
      img: img1,
      name: "name name",
    },
    {
      img: img2,
      name: "name name",
    },
    {
      img: img3,
      name: "name name",
    },
    {
      img: img4,
      name: "name name",
    },
    {
      img: img5,
      name: "name name",
    },
    {
      img: img6,
      name: "name name",
    },

    {
      img: img7,
      name: "name name",
    },
    {
      img: img8,
      name: "name name",
    },
    {
      img: img9,
      name: "name name",
    },
    {
      img: img10,
      name: "name name",
    },
    {
      img: img11,
      name: "name name",
    },
    {
      img: img12,
      name: "name name",
    },
    {
      img: img13,
      name: "name name",
    },
    {
      img: img14,
      name: "name name",
    },
    {
      img: img15,
      name: "name name",
    },
    {
      img: img16,
      name: "name name",
    },
    {
      img: img17,
      name: "name name",
    },
    {
      img: img18,
      name: "name name",
    },
    {
      img: img19,
      name: "name name",
    },
    {
      img: img20,
      name: "name name",
    },
    {
      img: img21,
      name: "name name",
    },
    {
      img: img22,
      name: "name name",
    },
    {
      img: img23,
      name: "name name",
    },
    {
      img: img24,
      name: "name name",
    },
    {
      img: img25,
      name: "name name",
    },
    {
      img: img26,
      name: "name name",
    },
    {
      img: img27,
      name: "name name",
    },
    {
      img: img28,
      name: "name name",
    },
    {
      img: img29,
      name: "name name",
    },
  ];
  return (
    <div className="gallery">
      <PageHero title={"gallery"} />
      <h2 style={{ textAlign: "center", marginTop: "5vh" }}>
        CLICK THE BELOW ICONS TO BE DIRECTED TO OUR SOCIAL MEDIA PAGES
      </h2>
      <div className="icons">
        <a
          href="https://www.facebook.com/cometogetherwidowsandorphans?mibextid=ZbWKwL"
          target={"_blank"}
          rel="noreferrer"
        >
          <FacebookIcon className="icon" />
        </a>
        <a
          href="https://www.facebook.com/cometogetherwidowsandorphans?mibextid=ZbWKwL"
          target={"_blank"}
          rel="noreferrer"
        >
          <InstagramIcon className="icon" />
        </a>
        <a
          href="https://twitter.com/Cometogether_Wi?t=nrNaPO8apLmPUSaFENY-Ew&s=08"
          target={"_blank"}
          rel="noreferrer"
        >
          <TwitterIcon className="icon" />
        </a>
        <a
          href="https://www.instagram.com/dianah_kamande/"
          target={"_blank"}
          rel="noreferrer"
        >
          <LinkedInIcon className="icon" />
        </a>
      </div>
      <div className="container">
        {galleryData.map((item, index) => (
          <GalleryCard
            key={index}
            bgImg={item.img}
            bgSize={"cover"}
            className="icon"
          />
        ))}
      </div>
      <div className="icons">
        <a
          href="https://www.facebook.com/cometogetherwidowsandorphans?mibextid=ZbWKwL"
          target={"_blank"}
          rel="noreferrer"
        >
          <FacebookIcon className="icon" />
        </a>
        <a
          href="https://www.facebook.com/cometogetherwidowsandorphans?mibextid=ZbWKwL"
          target={"_blank"}
          rel="noreferrer"
        >
          <InstagramIcon className="icon" />
        </a>
        <a
          href="https://twitter.com/Cometogether_Wi?t=nrNaPO8apLmPUSaFENY-Ew&s=08"
          target={"_blank"}
          rel="noreferrer"
        >
          <TwitterIcon className="icon" />
        </a>
        <a
          href="https://www.instagram.com/dianah_kamande/"
          target={"_blank"}
          rel="noreferrer"
        >
          <LinkedInIcon className="icon" />
        </a>
      </div>
    </div>
  );
}
