import React from "react";
import "./TeamCard.css";

export default function TeamCard({
  isCountyChapters,
  teamImg,
  name,
  role,
  bio,
}) {
  return (
    <div className={isCountyChapters ? "county-card" : `team-card`}>
      <div className="img" style={{ backgroundImage: `url(${teamImg})` }}></div>
      <div className="member-details">
        <h3>{name}</h3>
        <h5>{role}</h5>
        {/* <p>{bio}</p> */}
      </div>
    </div>
  );
}
