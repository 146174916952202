import React from "react";
import { Link } from "react-router-dom";
import "./WhatWeDo.css";

export default function () {
  return (
    <div className="what-we-do">
      <div className="container">
        {/* <div className="left">
          <div className="img"></div>
          <div className="img"></div>
        </div> */}
        <div className="content">
          <div className="section-header">
            <h2>What We Do</h2>
            <h4>
              Come Together Widows and Orphans Organization is a non -
              Governmental Organization that champions for the rights of widows
              and widows across all the 47 counties.
            </h4>
          </div>
          <p className="description">
            CTWOO has a routine of widow’s economic empowerment, widow’s legal
            empowerment and social support that is collectively done through
            partnerships with other likeminded organizations. CTWOO has been in
            the forefront in advocating for the enactment of widowed persons
            policies, legislations and frameworks to see that we have been able
            to achieve a free and a just society where widows can comfortably
            exploit their resources comfortably without any external factor that
            can drag them behind.
          </p>
          <Link to={"/what-we-do"}>
            <button>More about CTWOO</button>
          </Link>

          {/* <div className="activities">
            <div className="left">
              <div className="activity">
                <h3>Mobilization and formation of widows groups</h3>
              </div>
              <div className="activity">
                <h3>Guidance on registration with social protection offices</h3>
              </div>
            </div>
            <div className="activity middle">
              <h3>
                Provision of legal aid with an aim of overcoming loss and grief
              </h3>
            </div>
            <div className="right">
              <div className="activity">
                <h3>Widows economic empowerment for sustainability</h3>
              </div>
              <div className="activity">
                <h3>Widows empowerment through climate action integration</h3>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    </div>
  );
}
