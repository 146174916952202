import React, { useEffect, useState } from "react";
import PageHero from "../../components/PageHero";
import "./Mentorship.css";
import img from "../../img/mentorship.jpeg";
import img1 from "../../img/mentorship1.jpeg";

export default function Mentorship() {
  const [isMobileSize, setIsMobileSize] = useState(false);
  useEffect(() => {
    if (window.innerWidth < 992) {
      setIsMobileSize(true);
    } else {
      setIsMobileSize(false);
    }
  }, []);
  return (
    <div className="mentorship">
      <PageHero title={"Mentorship programme"} />
      <div
        className="container"
        style={
          isMobileSize
            ? { flexDirection: "column" }
            : { flexDirection: "row-reverse" }
        }
      >
        <div className="image" style={{ backgroundImage: `url(${img})` }}></div>
        <div className="description">
          Guiding young minds toward success is a cornerstone of our efforts.
          Our Mentorship Program pairs orphaned and vulnerable children with
          caring mentors who offer guidance, inspiration, and valuable life
          skills. This personalized support system nurtures their personal and
          emotional growth, preparing them for a well-rounded future.
        </div>
        <div
          className="image"
          style={{ backgroundImage: `url(${img1})` }}
        ></div>
      </div>
    </div>
  );
}
