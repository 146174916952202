import React, { useEffect, useState } from "react";
import PageHero from "../../components/PageHero";
import "./SkillsTraining.css";
import img from "../../img/widows-skills.jpeg";

export default function SkillsTraining() {
  const [isMobileSize, setIsMobileSize] = useState(false);
  useEffect(() => {
    if (window.innerWidth < 992) {
      setIsMobileSize(true);
    } else {
      setIsMobileSize(false);
    }
  }, []);
  return (
    <div className="skills-content">
      <PageHero title={"skills training"} />
      <div className="Content">
        <div className="content-container">
          <div className="content-img">
            <iframe
              src="https://www.youtube.com/embed/X5CDu3Bc9Gw?si=AUexCNmyKvsRyotn"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
          <div className="content-content">
            <p>
              At Come Together Widows and Orphans Organization, we are dedicated
              to empowering widows through skill development. Our comprehensive
              program encompasses a range of valuable skills, including bakery,
              tailoring, weaving and hairdressing. With a strong commitment to
              creating opportunities for widows, we believe in the
              transformative power of skill acquisition. By providing training
              in weaving and hairdressing, we equip widows with the tools they
              need to build sustainable livelihoods and secure their futures.
              Our efforts are rooted in the belief that skill empowerment is a
              key to breaking the cycle of dependency and fostering
              self-sufficiency.
            </p>
          </div>
        </div>
      </div>
      <div className="Content">
        <div
          className="content-container"
          style={
            isMobileSize
              ? { flexDirection: "column" }
              : { flexDirection: "row-reverse" }
          }
        >
          <div
            className="content-img"
            style={{ backgroundImage: `url(${img})` }}
          ></div>
          <div className="content-content">
            <p>
              We as well embrace the cottage industries including beadwork,
              knitting and crocket to widows. For instance, we have skills
              training centres in different counties like in Nairobi and Kiambu
              where we train widows and equip them with skills like tailoring
              skills, computer skills and knitting skills. Join us in our
              mission to uplift widows, enhance their self-esteem, and pave the
              way for brighter tomorrows.
            </p>
          </div>
        </div>
      </div>
    </div>
  );
}
