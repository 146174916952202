import React, { useState } from "react";
import "./Programmes.css";
import LocalFireDepartmentIcon from "@mui/icons-material/LocalFireDepartment";
import CrisisAlertIcon from "@mui/icons-material/CrisisAlert";
import PolicyIcon from "@mui/icons-material/Policy";
import WorkIcon from "@mui/icons-material/Work";
import ModelTrainingIcon from "@mui/icons-material/ModelTraining";
import SchoolIcon from "@mui/icons-material/School";
import { Link } from "react-router-dom";

export default function Programmes() {
  const [isHovered, setIsHovered] = useState(false);

  return (
    <div className="programmes">
      <div className="container">
        <h2 className="title">Our Programmes</h2>
        <div className="row first-row">
          <Link to={"/climate-action"} className={`column column-1`}>
            <div className="icon-div">
              <LocalFireDepartmentIcon className="icon" />
            </div>

            <h3>Climate Action (Trees) </h3>
            <p>
              Climate change and Widowhood- As Come Together Widows and Orphans
              Organization we have realised that the intersection of climate
              change and widowhood highlights a pressing concern that demands
              multifaceted attention. As an organization focused on empowering
              widows, recognizing the impact of climate change on their lives
              has been crucial for comprehensive support.
            </p>
            <button>Read More</button>
          </Link>
          <Link
            to={"/humanitarian-crisis-management"}
            className="column column-2"
          >
            <div className="icon-div">
              <CrisisAlertIcon className="icon" />
            </div>

            <h3>Humanitarian Crisis Management</h3>
            <p>
              At Come Together Widows and Orphans Organization, our mission goes
              beyond addressing the social and economic needs of widows and
              orphans. We recognize the critical role we play in humanitarian
              crisis management, and we have extended our efforts to tackle
              pressing challenges such as drought management and food insecurity
              mostly affecting widows and their children.
            </p>
            <button>Read More</button>
          </Link>
        </div>
        <div className="row second-row">
          <Link className="column column-1">
            <div className="icon-div">
              <PolicyIcon className="icon" />
            </div>

            <h3>Legal aid</h3>
            <p>
              At Come Together Widows and Orphans Organization we believe that
              legal aid stands as a foundational pillar in the journey towards
              empowering widows in Kenya before embarking on their economic
              empowerment. It serves as a crucial precursor, enabling widows to
              reclaim their rights, dignity, and agency within society. The
              essence of legal aid lies in its capacity to dismantle barriers,
              dispel injustices, and provide a solid framework upon which
              economic empowerment can be built.
            </p>
            <button>Read More</button>
          </Link>
          <Link to={"/economic-and-empowerment"} className="column column-2">
            <div className="icon-div">
              <WorkIcon className="icon" />
            </div>

            <h3>Economic empowerment</h3>
            <p>
              Beyond emotional support, we recognize the importance of economic
              empowerment in ensuring the long-term sustainability of widows'
              lives. We provide widows with the tools, resources, and training
              they need to become economically self-reliant. By fostering
              entrepreneurship and financial independence, we empower widows to
              create brighter futures for themselves and their families.
            </p>
            <button>Read More</button>
          </Link>
        </div>
        <div className="row third-row">
          <Link to={"/skills-training"} className="column column-1">
            <div className="icon-div">
              <ModelTrainingIcon className="icon" />
            </div>

            <h3>Skills Training</h3>
            <p>
              At Come Together Widows and Orphans Organization, we are dedicated
              to empowering widows through skill development. Our comprehensive
              program encompasses a range of valuable skills, including bakery,
              tailoring, weaving and hairdressing. With a strong commitment to
              creating opportunities for widows, we believe in the
              transformative power of skill acquisition.
            </p>
            <button>Read More</button>
          </Link>
          <Link to={"/education-support"} className="column column-2">
            <div className="icon-div">
              <SchoolIcon className="icon" />
            </div>

            <h3>Orphans education</h3>
            <p>
              We believe in the power of education to shape a brighter future.
              Our Orphans Program provides essential education support, ensuring
              that orphaned children have access to quality education, learning
              resources, and a conducive environment to thrive academically.We
              already has a total of 110 orphans and vulnerable children who we
              have been supporting their education.
            </p>
            <button>Read More</button>
          </Link>
        </div>
      </div>
    </div>
  );
}
