import React, { useEffect, useState } from "react";
import PageHero from "../../components/PageHero";
import "./GBV.css";

export default function GBV() {
  const [isMobileSize, setIsMobileSize] = useState(false);
  useEffect(() => {
    if (window.innerWidth < 992) {
      setIsMobileSize(true);
    } else {
      setIsMobileSize(false);
    }
  }, []);
  return (
    <div className="gbv">
      <PageHero title={"Gender based violence"} />

      <div className="Content">
        <div className="content-container">
          <div className="content-img">
            <iframe
              src="https://www.youtube.com/embed/nIN_-nl0B9Q?si=u61MP2Hxybav6T9j"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
          <div className="content-content">
            In conflict-ridden regions across the world, widows endure
            unimaginable hardships. The loss of spouses, often the family's
            primary breadwinners, plunges them into a cycle of poverty and
            despair. Displacement, trauma, and the lack of access to basic
            services compound their suffering. In our focus areas, such as
            war-torn regions in Sub-Saharan Africa and the Middle East,
            thousands of widows grapple with these challenges daily. According
            to recent data, over 70% of households in these areas are headed by
            widows, struggling to feed, educate, and shelter their children
            amidst the chaos of conflict. The situation is dire, and urgent
            action is needed. Our organization is dedicated to addressing this
            crisis, offering a lifeline to these resilient women who have lost
            so much. At Come Together Widows and orphans Organization we are on
            a mission to transform the lives of widows in conflict areas.
            Through a range of carefully tailored programs, we provide
            comprehensive support to empower these women on their journey to
            recovery and self-sufficiency. Our initiatives encompass Economic
            Empowerment, legal empowerment as well as psychosocial support.
          </div>
        </div>
      </div>
      <div className="Content">
        <div
          className="content-container"
          style={
            isMobileSize
              ? { flexDirection: "column" }
              : { flexDirection: "row-reverse" }
          }
        >
          <div className="content-img">
            <iframe
              src="https://www.youtube.com/embed/lBK6U6vLo9w?si=4nXu_WbeDDg1WGMg"
              title="YouTube video player"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe>
          </div>
          <div className="content-content">
            We are proud to share numerous success stories of widows who have
            emerged from the depths of despair to become self-reliant, resilient
            leaders in their communities. Their stories of hope and
            transformation inspire us every day. During our outreach Mission to
            Baringo, Turkana, Pokot, and Elgeyo Marakwet Counties, we had the
            privilege of engaging with widows in the region, imparting essential
            knowledge about their rights and providing invaluable psychosocial
            support through our partnership with FIDA Kenya. Thanks to the
            generous support of the Global Fund for Widows and the Women
            Enterprise Fund, we were able to empower these resilient women by
            enlightening them about women's economic empowerment. Through these
            collaborative efforts, we have taken significant strides in
            promoting the rights, well-being, and economic independence of
            widows in these underserved communities, fostering a brighter future
            for them and their families.
          </div>
        </div>
      </div>
    </div>
  );
}
