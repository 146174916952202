import React from "react";
import Content from "../../components/Content";
import PageHero from "../../components/PageHero";
import TeamCard from "../../components/TeamCard";
import "./CountyChapters.css";
import img1 from "../../img/chapters/vihiga.jpeg";
import img2 from "../../img/chapters/kilifi.jpeg";
import img3 from "../../img/chapters/elgeyo.jpeg";
import img4 from "../../img/chapters/taitataveta.jpeg";
import img5 from "../../img/chapters/samburu.jpeg";

export default function CountyChapters() {
  const teamData = [
    {
      img: img1,
      name: "Vihiga",
    },
    {
      img: img2,
      name: "Kilifi",
    },
    {
      img: img3,
      name: "Elgeyo Markwet",
    },
    {
      img: img4,
      name: "Taita Taveta",
    },
    {
      img: img5,
      name: "Samburu",
    },
  ];
  return (
    <div className="county-chapters">
      <PageHero title={"county chapters"} />
      <div className="container">
        {teamData.map((item, index) => (
          <TeamCard
            isCountyChapters={true}
            key={index}
            teamImg={item.img}
            name={item.name}
            role={item.role}
            bio={item.bio}
          />
        ))}
      </div>
    </div>
  );
}
