import React, { useEffect, useState } from "react";
import "./NewsCard.css";

export default function NewsCard({ topic, headline, bgImgs }) {
  const [cardBgImage, setCardBgImage] = useState(null);
  useEffect(() => {
    const intervalId = setInterval(() => {
      const randomIndex = Math.floor(Math.random() * bgImgs.length);
      setCardBgImage(bgImgs[randomIndex]);
    }, 2000);

    return () => {
      clearInterval(intervalId);
    };
  }, [bgImgs]);

  return (
    <div className="news-card">
      <div className="container">
        <div
          className="img"
          style={{
            backgroundImage: `url(${cardBgImage})`,
          }}
        ></div>
        <div className="topic">{topic}</div>
        <p className="headline">{headline}</p>
        <div className="more"></div>
      </div>
    </div>
  );
}
